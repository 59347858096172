import {
  faCog,
  faHandHoldingUsd,
  faReceipt,
  faSignOutAlt,
  faTrophy,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons/faGift";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap";

import { getAccountName, getAccountUser, getEosAccount } from "selectors/loginSelectors";

import { logout } from "actions/loginActions.ts";

import DropdownToggle from "components/common/DropdownToggle.tsx";
import HashLink from "components/common/HashLink";
import NavLink from "components/common/NavLink.tsx";

import whitelabelUtils from "utils/whitelabelUtils.ts";

const UserMenu = ({ side = false, className }) => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const account = useSelector(getAccountName);
  const eosAccount = useSelector(getEosAccount);
  const user = useSelector(getAccountUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Dropdown
      className={`user-menu-toggler ${className}`}
      size="sm"
      direction="down"
      isOpen={isDropdownOpen}
      toggle={() => setisDropdownOpen(!isDropdownOpen)}
    >
      <DropdownToggle caret caretStacked className="text-left">
        <span className={cx("user-name", { "user-name-desktop": !side })}>
          <FontAwesomeIcon className="button-icon-start" icon={faUser} />
          <span>{account}</span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="full-width">
        <DropdownItem tag={HashLink} to="wallet" className="hash-route">
          <FontAwesomeIcon icon={faWallet} /> {t("Wallet")}
        </DropdownItem>
        {user && user.eosAccount && whitelabelUtils.dividends() ? (
          <DropdownItem tag={HashLink} to="dividends" className="hash-route">
            <FontAwesomeIcon icon={faHandHoldingUsd} /> {t("Dividends")}
          </DropdownItem>
        ) : null}
        <DropdownItem tag={HashLink} to="bonus" className="hash-route">
          <FontAwesomeIcon icon={faGift} /> {t("Bonus")}
        </DropdownItem>

        {eosAccount && (
          <>
            <DropdownItem tag={HashLink} to="leaderboard" className="hash-route">
              <FontAwesomeIcon icon={faTrophy} /> {t("Leaderboard")}
            </DropdownItem>
            <DropdownItem tag={NavLink} to="/history">
              <FontAwesomeIcon icon={faReceipt} /> {t("Bet History")}
            </DropdownItem>
          </>
        )}

        <DropdownItem tag={HashLink} to="settings" className="hash-route">
          <FontAwesomeIcon icon={faCog} /> {t("Settings")}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => dispatch(logout())}>
          <FontAwesomeIcon icon={faSignOutAlt} /> {t("Logout")}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withRouter(UserMenu);
