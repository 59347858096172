import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import { setSportFilter } from "actions/sportActions";

import { debounce } from "utils/lodash.ts";

import { getSportFilter } from "../../selectors/sportsSelectors";

const SportFilter = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterValue = useSelector(getSportFilter);
  const inputRef = useRef();

  const filterFn = useMemo(
    () => debounce((value) => dispatch(setSportFilter(value + "")), 300),
    [dispatch, setSportFilter]
  );

  const onFilter = useCallback(
    (e) => {
      e.preventDefault();
      if (inputRef?.current) {
        inputRef.current.value = e.target.value;
      }
      filterFn(e.target.value);
    },
    [inputRef?.current, filterFn]
  );

  const cleanFilter = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
    dispatch(setSportFilter(""));
  }, [dispatch, inputRef?.current]);

  useEffect(() => {
    return () => {
      dispatch(setSportFilter(""));
    };
  }, [dispatch]);

  return (
    <InputGroup className={className}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        type="text"
        placeholder={t("Search Event")}
        defaultValue={filterValue}
        onChange={onFilter}
        innerRef={inputRef}
      />
      <InputGroupAddon addonType="append">
        <Button onClick={cleanFilter}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

SportFilter.propTypes = {
  className: PropTypes.string,
};

export default SportFilter;
