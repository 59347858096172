import { marketType } from "@sbetdev2/dbmodel/enums";

import { toDate, toHHMM } from "./dateUtils";

type GameType = {
  name: string;
  providerTitle: string;
};

type LeagueTypes = {
  sport: number;
  name: string;
};

type EventType = {
  league: number;
  home: string;
  away: string;
  starts: Date;
  sport: number;
  odds?: { [key: number]: { bet: boolean; home: number; away: number; draw: number } };
};

type DetectPagePropsTypes = {
  type: string;
  sport: string;
  league: string;
  event: string;
  leagueId: string;
  eventId: string;
  category: string;
  provider: string;
  game: string;
  casinoAltCoin: string;
  categoryWithAltCoin: string;
  staticSlug: string;
  altcoin: string;
  pathname: string;
  esportsGame: string;
};

export const detectPageType = (params: DetectPagePropsTypes, language = "en") => {
  const {
    type,
    sport,
    league,
    event,
    leagueId,
    eventId,
    category,
    provider,
    game,
    casinoAltCoin,
    categoryWithAltCoin,
    staticSlug,
    pathname: path,
    altcoin,
    esportsGame,
  } = params;

  let pathname = path.startsWith(`/${language}`)
    ? path.replace(`/${language}`, "")
    : path;
  if (pathname.length > 1 && pathname[pathname.length - 1] === "/") {
    pathname = pathname.slice(0, pathname.length - 1);
  }

  if (type === "empty") {
  } else if (pathname.includes("/esports-games") && esportsGame) {
    return { type: "esportsGameGroup", slug: esportsGame };
  } else if ((event || eventId) && !["/live", "/sports", "/"].includes(pathname)) {
    return {
      type: type === "live" ? "eventLive" : "eventPrematch",
      slug: event || eventId,
    };
  } else if (type && sport && !["/live", "/sports", "/"].includes(pathname)) {
    if (league || leagueId) {
      return {
        type: type === "live" ? "leagueLive" : "league",
        slug: league || leagueId,
      };
    } else {
      return { type: type === "live" ? "sportLive" : "sport", slug: sport };
    }
  } else if (pathname === "/casino/all-games") {
    return { type: "casinoAllGames", slug: "all-games" };
  } else if (category || categoryWithAltCoin) {
    return { type: "casinoCategory", slug: category || categoryWithAltCoin };
  } else if (casinoAltCoin) {
    return { type: "casino", slug: casinoAltCoin };
  } else if (game && provider) {
    return { type: "casinoGame", slug: `${provider}/${game}` };
  } else if (provider) {
    return { type: "casinoProvider", slug: provider };
  } else if (pathname === "/casino") {
    return { type: "casino", slug: "casino" };
  } else if (pathname === "/faq") {
    return { type: "static", slug: "faq" };
  } else if (pathname === "/promotion") {
    return { type: "static", slug: "promotion" };
  } else if (staticSlug) {
    return { type: "static", slug: staticSlug };
  } else if (pathname === "/") {
    return { type: "home", slug: "/" };
  } else if (altcoin) {
    return { type: "home", slug: altcoin };
  } else if (pathname === "/signup") {
    return { type: "home", slug: "/" };
  } else if (pathname === "/live") {
    return { type: "liveHome", slug: "live" };
  } else if (pathname === "/sports") {
    return { type: "sportsHome", slug: "sports" };
  }

  return {};
};

export const getSportName = (sportId: number) =>
  ({
    1: "Soccer",
    2: "Baseball",
    3: "Basketball",
    4: "Boxing/MMA",
    5: "Boxing/MMA",
    7: "Esports",
    8: "Hockey",
    9: "Cricket",
    10: "Football",
    11: "Tennis",
    12: "AFL",
    13: "Rugby Union",
    14: "Rugby League",
    15: "Volleyball",
    16: "Handball",
    17: "Table Tennis",
  }[sportId] || "");

export const getSportNameBySlug = (slug: string) =>
  ({
    soccer: "Soccer",
    baseball: "Baseball",
    basketball: "Basketball",
    "boxing-mma": "Boxing/MMA",
    esports: "Esports",
    hockey: "Hockey",
    cricket: "Cricket",
    football: "Football",
    tennis: "Tennis",
    afl: "AFL",
    "rugby-union": "Rugby Union",
    "rugby-league": "Rugby League",
    volleyball: "Volleyball",
    handball: "Handball",
    "table-tennis": "Table Tennis",
  }[slug] || "");

export const getCasinoCategoryBySlug = (slug: string) =>
  ({
    roulette: "Roulette",
    slots: "Slots",
    "live-casino": "Live Casino",
    "table-games": "Table Games",
    "provably-fair": "Provably Fair",
    instant: "Instant Games",
    favourite: "Favourites",
    recent: "Recent",
  }[slug] || slug);

export const getPageContent = (
  content: string,
  contentVariables: { [key: string]: string }
) => {
  let html = content;
  for (const variable of Object.keys(contentVariables)) {
    for (let i = 0; i < 15; i++) {
      html = html.replace(
        "{{" + variable + "}}",
        contentVariables[variable] ? contentVariables[variable].trim() : ""
      );
    }
  }

  return [
    {
      type: "text-expanded",
      data: { html: html.includes("{{") ? "" : html },
    },
  ];
};

export const getPageMeta = (
  meta: string,
  contentVariables: { [key: string]: string }
) => {
  let result = meta || "";
  for (const variable of Object.keys(contentVariables)) {
    for (let i = 0; i < 15; i++) {
      result = result.replace("{{" + variable + "}}", contentVariables[variable]);
    }
  }
  return result;
};

const eventOddsMarkets = [
  marketType.winner3Match,
  marketType.winner2Match,
  marketType.winner2MatchAll,
];

export const getEventOdds = (event: EventType) => {
  if (!event?.odds) return "";
  let odd;
  for (const market of eventOddsMarkets) {
    if (event.odds[market] && event.odds[market].bet) {
      odd = event.odds[market];
      break;
    }
  }

  if (!odd) return "";

  const result = [];
  if (odd.home) result.push(`P1: ${odd.home}`);
  if (odd.away) result.push(`P2: ${odd.away}`);
  if (odd.draw) result.push(`Draw: ${odd.draw}`);

  return result.join(" | ");
};

export const getContentVariables = (
  t: (str: string) => string,
  {
    type,
    slug,
    event,
    leagues,
    games,
    game,
    providerTitle,
  }: {
    type: string;
    slug: string;
    event: EventType;
    leagues: { byId: { [key: string]: LeagueTypes } };
    games: GameType[];
    game: GameType;
    providerTitle: string;
  },
  { league: leagueOrig }: any = {}
) => {
  const contentVariables: { [key: string]: string } = {};
  if (["eventPrematch", "eventLive"].includes(type)) {
    const league = event && event.league && leagues.byId && leagues.byId[event.league];

    if (event && league) {
      contentVariables.home = event.home;
      contentVariables.away = event.away;
      contentVariables.name = `${contentVariables.home} - ${contentVariables.away}`;
      contentVariables.event = contentVariables.name;
      contentVariables.date = event.starts ? toDate(new Date(event.starts)) : "";
      contentVariables.time = event.starts ? toHHMM(new Date(event.starts)) : "";
      contentVariables.sport = t(getSportName(event.sport) || "Sport");
      contentVariables.sportLocal = contentVariables.sport;
      const leagueParts = league.name.split(":");
      contentVariables.league = leagueParts[1] ? leagueParts[1] : league.name;
      contentVariables.country = leagueParts[1] ? leagueParts[0] : "World";
      contentVariables.odds = getEventOdds(event);
    }
  } else if (["league", "leagueLive"].includes(type)) {
    const leagueId = slug.split("-").pop();
    const league = leagueId ? leagues.byId[leagueId] || leagueOrig : leagueOrig;
    if (league) {
      const leagueParts = league.name.split(":");
      contentVariables.league = leagueParts[1] ? leagueParts[1] : league.name;
      contentVariables.country = leagueParts[1] ? leagueParts[0] : "World";
      contentVariables.sport = getSportName(league.sport) || "Sport";
      contentVariables.sportLocal = t(contentVariables.sport);
    }
  } else if (["sport", "sportLive"].includes(type)) {
    contentVariables.sport = t(getSportNameBySlug(slug));
    contentVariables.sportLocal = contentVariables.sport;
    contentVariables.category = contentVariables.sport;
  } else if (type === "casinoProvider") {
    contentVariables.provider = games?.[0]?.providerTitle || providerTitle;
  } else if (type === "casinoCategory") {
    contentVariables.category = getCasinoCategoryBySlug(slug);
  } else if (type === "casinoGame") {
    if (game) {
      contentVariables.game = game.name;
      contentVariables.provider = game.providerTitle;
    }
  }

  return contentVariables;
};
