import PropTypes from "prop-types";
import React, { memo, useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";

import { getAccountIsVip } from "selectors/loginSelectors";
import { getPageSeoContent } from "selectors/pageSelectors";
import { getSportFilter, getSportView } from "selectors/sportsSelectors";

import {
  loadSport,
  monitorSport,
  monitorSportEnd,
  setActiveSport,
  setSportFilter,
} from "actions/sportActions";

import ErrorMsg from "components/common/ErrorMsg";
import Loading from "components/common/Loaders/Loading.tsx";
import NoDataMsg from "components/common/NoDataMsg";
import GroupEvents from "components/sports/GroupEvents";

import { getPrimaryLeagueOrderThreshold } from "utils/eventUtils";
import { getSlugWithoutId } from "utils/urlUtils.ts";

const SportEvents = ({ sportId, league, game, live = false, esportsGame }) => {
  const dispatch = useDispatch();
  const view = useSelector(getSportView);
  const filter = useSelector(getSportFilter);
  const isVip = useSelector(getAccountIsVip);
  const seoContent = useSelector(getPageSeoContent);
  const prevProps = useRef({});
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setActiveSport(sportId, live));

    return () => {
      dispatch(setActiveSport());
    };
  }, [sportId, live]);

  const getSport = (refresh = true) => {
    batch(() => {
      dispatch(loadSport(sportId, live, refresh));
      dispatch(monitorSport(sportId, live));
    });
  };

  useEffect(() => {
    if (prevProps.current.sportId) {
      batch(() => {
        dispatch(monitorSportEnd(prevProps.current.sportId, prevProps.current.live));
        dispatch(setSportFilter(""));
      });
    }
    getSport();

    prevProps.current = {
      sportId,
      live,
      isVip,
    };

    return () => {
      dispatch(monitorSportEnd(sportId, live));
    };
  }, [sportId, live, isVip]);

  const isSeoLeague = useMemo(() => {
    if (!view || !league || live) {
      return false;
    }

    return view.groups.some((group) =>
      group.leagues.some((l) => {
        const { betRisk, slug } = l.league;
        return slug.includes(league) && (betRisk === 0.5 || !!seoContent);
      })
    );
  }, [view, league, seoContent, live]);

  if (!view) return null;

  const { sport, groups } = view;

  const shouldOpen =
    groups.length <= 3 ||
    (groups.length <= 8 && groups.reduce((p, x) => p + x.count, 0) <= 8);

  const error = live ? sport.liveError : sport.error;
  const loading = live ? sport.liveLoading : sport.loading;
  const loaded = live ? sport.liveLoaded : sport.loaded;

  return (
    <>
      {league && !live && !isSeoLeague && (
        <Helmet>
          <meta name="robots" content="noindex follow" />
        </Helmet>
      )}
      <main className="mt-2" style={{ paddingBottom: "60px" }}>
        {error && <ErrorMsg>{error}</ErrorMsg>}
        {!loaded && !groups.length && loading && <Loading />}
        {loaded && !groups.length && (
          <NoDataMsg>{t("No events available for betting")}</NoDataMsg>
        )}
        {groups.map((x) => {
          if (!!game && !x.slug.includes(game)) return null;

          const hasRequestedLeague =
            !!league && x.leagues.find((l) => getSlugWithoutId(l.league.slug) === league);
          const startOpen =
            shouldOpen ||
            hasRequestedLeague ||
            (esportsGame && esportsGame === x.slug) ||
            (live && x.order && x.order < getPrimaryLeagueOrderThreshold(true));

          return (
            <GroupEvents
              key={x.name + sport._id}
              live={live}
              startOpen={!!startOpen}
              forceOpen={!!filter}
              requestedLeague={league}
              scrollTo={!!startOpen && !!hasRequestedLeague}
              group={x}
              sportId={sportId}
            />
          );
        })}
      </main>
    </>
  );
};

SportEvents.propTypes = {
  sportId: PropTypes.number,
  live: PropTypes.bool,
};

export default memo(SportEvents);
