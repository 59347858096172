import { bonusCurrenciesOrder, currencies } from "constants/currencies.ts";
import { CurrencyType } from "constants/types";

import { formatTokenAmount } from "utils/currencyUtils";
import { addNumbers } from "utils/numbersUtils.ts";

import {
  BalanceType,
  RatesType,
  ValidateWithdrawPropsType,
  ValidationTypes,
} from "./types";

export const validateWithdrawMinSum = ({
  value,
  balances,
  type,
  rates,
  currency,
  fee,
}: ValidateWithdrawPropsType) => {
  if (!value || value <= 0) {
    return { isValid: false, error: "Invalid amount" };
  }
  if (currencies[currency].fromDisplayValue(value) > balances?.[currency]) {
    return {
      isValid: false,
      error: "Amount should be equal or less than the available balance",
    };
  }

  const checkAmount = (rates: RatesType) => {
    // 1$ equivalent in token
    const tokenMinWithdraw = 1 / rates[currency];
    const minAmount = addNumbers(
      Number(formatTokenAmount(tokenMinWithdraw, currency)),
      Number(fee)
    );

    if (value < minAmount) {
      return `Min. ${type} amount: ${minAmount} ${currencies[currency].displayName}`;
    }
  };

  if (type === ValidationTypes.WITHDRAW) {
    const error = checkAmount(rates as RatesType);
    if (error) {
      return { isValid: false, error };
    }
  }
  return { isValid: true, error: undefined };
};

export const findBonusToken = (balance: BalanceType) => {
  return bonusCurrenciesOrder.find((token) => balance[token] > 0);
};

export const formatOrderViaBonus = (
  balance: BalanceType,
  casinoBalance: BalanceType,
  order: string[]
) => {
  if (!balance || !order || !casinoBalance) return [];

  const bonusCurrencyAvailable = findBonusToken(balance) || findBonusToken(casinoBalance);

  if (!bonusCurrencyAvailable) return order;
  if (order.includes(bonusCurrencyAvailable)) return order;

  const mainCurrencyPosition = order.findIndex(
    (name) => name === currencies[bonusCurrencyAvailable]?.realName
  );
  const newOrder = [...order];
  newOrder.splice(mainCurrencyPosition + 1, 0, bonusCurrencyAvailable);
  return newOrder;
};

export const getTotalBalance = (
  balances: BalanceType | null,
  currency: CurrencyType | null,
  rates: Record<string, number>
) => {
  if (!rates || !currency || !balances || !rates[currency.name]) return 0;
  let total = 0;
  for (const [currencyCode, amount] of Object.entries(balances)) {
    if (!rates[currencyCode]) continue;
    total += amount * rates[currencyCode];
  }
  return total;
};
