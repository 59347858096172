import { supportedProviders } from "@sbetdev2/dbmodel/enums";
import { Tokens } from "@sbetdev2/sbet-utils/constants";

import { BonusTokens } from "containers/popups/Wallet/types.ts";

import { CurrencyType } from "./types.ts";

export const depositDescription = {
  [Tokens.BTC]: [
    { text: "Coins will be deposited after 1 network confirmation" },
    { text: "Deposit delay can be up to 5 minutes after network confirmation" },
    { text: "Min deposit {{amount}}", tParams: { amount: "0.00005 BTC" } },
  ],
  [Tokens.BCH]: [{ text: "Coins will be deposited after 6 network confirmations" }],
  [Tokens.DOGE]: [{ text: "Coins will be deposited after 12 network confirmations" }],
  [Tokens.LTC]: [
    { text: "Coins will be deposited after 2 network confirmations" },
    { text: "Deposit delay can be up to 5 minutes after network confirmation" },
  ],
  [Tokens.USDT]: [{ text: "Coins will be deposited after 6 network confirmations" }],
  [Tokens.ETH]: [{ text: "Coins will be deposited after 6 network confirmations" }],
  [Tokens.TRX_USDT]: [{ text: "Coins will be deposited after 20 network confirmations" }],
  [Tokens.EOS]: [{ text: "Coins will be deposited after 1 network confirmation." }],
  [Tokens.SOL]: [{ text: "Coins will be deposited after 50 network confirmations" }],
  [Tokens.USDC]: [{ text: "Coins will be deposited after 6 network confirmations" }],
  [Tokens.TON]: [{ text: "Coins will be deposited after 1 network confirmation" }],
  [Tokens.ADA]: [{ text: "Coins will be deposited after 15 network confirmations" }],
  [Tokens.XRP]: [{ text: "Coins will be deposited after 1 network confirmation" }],
  [Tokens.TRX]: [{ text: "Coins will be deposited after 20 network confirmations" }],
  [Tokens.SHIB]: [{ text: "Coins will be deposited after 6 network confirmations" }],
  [Tokens.BNB]: [{ text: "Coins will be deposited after 60 network confirmations" }],
  [Tokens.DAI]: [{ text: "Coins will be deposited after 6 network confirmations" }],
  [Tokens.TRUMP]: [{ text: "Coins will be deposited after 50 network confirmations" }],
};

export const tokenNetworks = {
  [Tokens.EOS]: [
    {
      name: "EOS",
      value: Tokens.EOS,
      token: Tokens.EOS,
      ptoken: Tokens.EOS,
    },
  ],
  [Tokens.SBET]: [
    {
      name: "EOS",
      value: Tokens.EOS,
      token: Tokens.EOS,
      ptoken: Tokens.SBET,
    },
  ],
  [Tokens.BCH]: [
    { name: "Bitcoin Cash", value: Tokens.BCH, token: Tokens.BCH, ptoken: Tokens.BCH },
  ],
  [Tokens.DOGE]: [
    { name: "Dogecoin", value: Tokens.DOGE, token: Tokens.DOGE, ptoken: Tokens.DOGE },
  ],
  [Tokens.USDT]: [
    {
      name: "Ethereum (ERC20)",
      value: Tokens.ETH,
      token: Tokens.USDT,
      ptoken: Tokens.USDT,
    },
    {
      name: "Tron (TRC20)",
      value: Tokens.TRX,
      token: Tokens.TRX_USDT,
      ptoken: Tokens.USDT,
    },
  ],
  [Tokens.BTC]: [
    { name: "Bitcoin", value: Tokens.BTC, token: Tokens.BTC, ptoken: Tokens.BTC },
  ],
  [Tokens.LTC]: [
    { name: "Litecoin", value: Tokens.LTC, token: Tokens.LTC, ptoken: Tokens.LTC },
  ],
  [Tokens.ETH]: [
    { name: "Ethereum", value: Tokens.ETH, token: Tokens.ETH, ptoken: Tokens.ETH },
  ],
  [Tokens.SOL]: [
    { name: "Solana", value: Tokens.SOL, token: Tokens.SOL, ptoken: Tokens.SOL },
  ],
  [Tokens.USDC]: [
    {
      name: "Ethereum (ERC20)",
      value: Tokens.ETH,
      token: Tokens.USDC,
      ptoken: Tokens.USDC,
    },
  ],
  [Tokens.TON]: [
    { name: "Toncoin", value: Tokens.TON, token: Tokens.TON, ptoken: Tokens.TON },
  ],
  [Tokens.BNB]: [
    { name: "BSC(BEP20)", value: Tokens.BNB, token: Tokens.BNB, ptoken: Tokens.BNB },
  ],
  [Tokens.ADA]: [
    { name: "Cardano", value: Tokens.ADA, token: Tokens.ADA, ptoken: Tokens.ADA },
  ],
  [Tokens.XRP]: [
    { name: "Ripple", value: Tokens.XRP, token: Tokens.XRP, ptoken: Tokens.XRP },
  ],
  [Tokens.TRX]: [
    { name: "Tron(TRC20)", value: Tokens.TRX, token: Tokens.TRX, ptoken: Tokens.TRX },
  ],
  [Tokens.SHIB]: [
    {
      name: "Ethereum (ERC20)",
      value: Tokens.ETH,
      token: Tokens.SHIB,
      ptoken: Tokens.SHIB,
    },
  ],
  [Tokens.DAI]: [
    {
      name: "Ethereum (ERC20)",
      value: Tokens.ETH,
      token: Tokens.DAI,
      ptoken: Tokens.DAI,
    },
  ],
  [Tokens.TRUMP]: [
    { name: "Solana", value: Tokens.SOL, token: Tokens.TRUMP, ptoken: Tokens.TRUMP },
  ],
};

export const bonusCurrencies = {
  [Tokens.SEOS]: {
    name: Tokens.SEOS,
    realName: Tokens.EOS,
    code: "sbetbonuscur",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "EOS",
    bonusName: "EOS (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SBTC]: {
    name: Tokens.SBTC,
    realName: Tokens.BTC,
    code: "sbetbonuscur",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    bonusName: "BTC (Bonus)",
    fromDisplayValue: (x: number) => x && x / 1000,
    toDisplayValue: (x: number) => x && x * 1000,
  },
  [Tokens.SBCH]: {
    name: Tokens.SBCH,
    realName: Tokens.BCH,
    code: "sbetbonuscur",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "BCH",
    bonusName: "BCH (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SDOGE]: {
    name: Tokens.SDOGE,
    realName: Tokens.DOGE,
    code: "sbetbonuscur",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "DOGE",
    bonusName: "DOGE (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.STON]: {
    name: Tokens.STON,
    realName: Tokens.TON,
    code: "sbetbonuscur",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "TON",
    bonusName: "TON (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SBNB]: {
    name: Tokens.SBNB,
    realName: Tokens.BNB,
    code: "sbetbonuscur",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "BNB",
    bonusName: "BNB (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SADA]: {
    name: Tokens.SADA,
    realName: Tokens.ADA,
    code: "sbetbonuscur",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "ADA",
    bonusName: "ADA (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SXRP]: {
    name: Tokens.SXRP,
    realName: Tokens.XRP,
    code: "sbetbonuscur",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "XRP",
    bonusName: "XRP (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.STRX]: {
    name: Tokens.STRX,
    realName: Tokens.TRX,
    code: "sbetbonuscur",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "TRX",
    bonusName: "TRX (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SSHIB]: {
    name: Tokens.SSHIB,
    realName: Tokens.SHIB,
    code: "sbetbonuscur",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "SHIB",
    bonusName: "SHIB (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SDAI]: {
    name: Tokens.SDAI,
    realName: Tokens.DAI,
    code: "sbetbonuscur",
    precision: 9,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "DAI",
    bonusName: "DAI (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.STRUMP]: {
    name: Tokens.STRUMP,
    realName: Tokens.TRUMP,
    code: "sbetbonuscur",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "TRUMP",
    bonusName: "TRUMP (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SLTC]: {
    name: Tokens.SLTC,
    realName: Tokens.LTC,
    code: "sbetbonuscur",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    bonusName: "LTC (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SETH]: {
    name: Tokens.SETH,
    realName: Tokens.ETH,
    code: "sbetbonuscur",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    bonusName: "ETH (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SUSD]: {
    name: Tokens.SUSD,
    realName: Tokens.USDT,
    code: "sbetbonuscur",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "USDT",
    bonusName: "USDT (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SSOL]: {
    name: Tokens.SSOL,
    realName: Tokens.SOL,
    code: "sbetbonuscur",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "SOL",
    bonusName: "SOL (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SUSDC]: {
    name: Tokens.SUSDC,
    realName: Tokens.USDC,
    code: "sbetbonuscur",
    precision: 6,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 6,
    displayName: "USDC",
    bonusName: "USDC (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
};

const basicCurrencies = {
  [Tokens.EOS]: {
    name: Tokens.EOS,
    code: "eosio.token",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "EOS",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    walletName: "EOS",
    mainNetwork: "EOS",
  },
  [Tokens.SBET]: {
    name: Tokens.SBET,
    code: "sportbetsbet",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "SBET",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    walletName: "SBET",
    mainNetwork: "EOS",
  },
  [Tokens.BTC]: {
    name: Tokens.BTC,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    fromDisplayValue: (x: number) => x && x / 1000,
    toDisplayValue: (x: number) => x && x * 1000,
    provider: supportedProviders.bitgo,
    walletName: "BTC",
    mainNetwork: "Bitcoin",
  },
  [Tokens.SOL_USDT]: {
    name: Tokens.SOL_USDT,
    code: "sportbetcurr",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "SOL:USDT",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "SOL USDT",
    mainNetwork: "USDT (SOL)",
    feeName: "USDT",
  },
  [Tokens.BCH]: {
    name: Tokens.BCH,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "BCH",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "BCH",
    mainNetwork: "Bitcoin Cash",
  },
  [Tokens.DOGE]: {
    name: Tokens.DOGE,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "DOGE",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "DOGE",
    mainNetwork: "Dogecoin",
  },
  [Tokens.TON]: {
    name: Tokens.TON,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "TON",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "TON",
    mainNetwork: "Toncoin",
  },
  [Tokens.BNB]: {
    name: Tokens.BNB,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "BNB",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "BNB",
    mainNetwork: "BSC (BEP-20)",
  },
  [Tokens.ADA]: {
    name: Tokens.ADA,
    code: "sportbetcurr",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "ADA",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "ADA",
    mainNetwork: "Cardano",
  },
  [Tokens.XRP]: {
    name: Tokens.XRP,
    code: "sportbetcurr",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "XRP",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "XRP",
    mainNetwork: "Ripple",
  },
  [Tokens.TRX]: {
    name: Tokens.TRX,
    code: "sportbetcurr",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "TRX",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "TRX",
    mainNetwork: "Tron",
  },
  [Tokens.SHIB]: {
    name: Tokens.SHIB,
    code: "sportbetcurr",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "SHIB",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "SHIB",
    mainNetwork: "Ethereum",
  },
  [Tokens.DAI]: {
    name: Tokens.DAI,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "DAI",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "DAI",
    mainNetwork: "Ethereum",
    feeName: "DAI",
  },
  [Tokens.TRUMP]: {
    name: Tokens.TRUMP,
    code: "sportbetcurr",
    precision: 6,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "TRUMP",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "TRUMP",
    mainNetwork: "OFFICIAL TRUMP",
  },
  [Tokens.LTC]: {
    name: Tokens.LTC,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 6,
    fullDisplayPrecision: 6,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    mainNetwork: "Litecoin",
    pNetwork: "EOS (pLTC)",
    walletName: "LTC",
  },
  [Tokens.ETH]: {
    name: Tokens.ETH,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    mainNetwork: "Ethereum",
    pNetwork: "EOS (pETH)",
    walletName: "ETH",
  },
  [Tokens.USDT]: {
    name: Tokens.USDT,
    code: "sportbetcurr",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "USDT",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "USDT",
    mainNetwork: "Tether",
    pNetwork: "EOS",
    feeName: "USDT",
  },
  [Tokens.SOL]: {
    name: Tokens.SOL,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "SOL",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "SOL",
    mainNetwork: "Solana",
  },
  [Tokens.USDC]: {
    name: Tokens.USDC,
    code: "sportbetcurr",
    precision: 6,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 6,
    displayName: "USDC",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "USDC",
    mainNetwork: "USD Coin",
    feeName: "USDC",
  },
  [Tokens.TRX_USDT]: {
    name: Tokens.TRX_USDT,
    code: "sportbetcurr",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "TRX:USDT",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "TRX USDT",
    mainNetwork: "USDT (TRC20)",
    pNetwork: "EOS network (USDT-EOS)",
    feeName: "USDT",
  },
};

export const currencies: Record<Tokens, CurrencyType> = {
  ...basicCurrencies,
  ...bonusCurrencies,
};

export const bonusCurrenciesOrder: BonusTokens[] = [
  Tokens.SBTC,
  Tokens.SSOL,
  Tokens.SUSD,
  Tokens.SUSDC,
  Tokens.SETH,
  Tokens.SXRP,
  Tokens.SLTC,
  Tokens.STRX,
  Tokens.SBNB,
  Tokens.STON,
  Tokens.SDOGE,
  Tokens.STRUMP,
  Tokens.SBCH,
  Tokens.SADA,
  Tokens.SSHIB,
  Tokens.SDAI,
  Tokens.SEOS,
];

export const currenciesOrderSocial = [
  Tokens.BTC,
  Tokens.SOL,
  Tokens.USDT,
  Tokens.USDC,
  Tokens.ETH,
  Tokens.XRP,
  Tokens.LTC,
  Tokens.TRX,
  Tokens.BNB,
  Tokens.TON,
  Tokens.DOGE,
  Tokens.TRUMP,
  Tokens.BCH,
  Tokens.ADA,
  Tokens.SHIB,
  Tokens.DAI,
  Tokens.EOS,
  Tokens.SBET,
];

export const fiatCurrencies = {
  USD: { title: "USD", currency: "USD", active: true, sign: "$" },
  GBP: { title: "GBP", currency: "GBP", active: true, sign: "£" },
  EUR: { title: "EUR", currency: "EUR", active: true, sign: "€" },
  CAD: { title: "CAD", currency: "CAD", active: true, sign: "C$" },
  AUD: { title: "AUD", currency: "AUD", active: true, sign: "A$" },
  CNY: { title: "CNY", currency: "CNY", active: true, sign: "¥" },
  KRW: { title: "KRW", currency: "KRW", active: true, sign: "₩" },
  JPY: { title: "JPY", currency: "JPY", active: true, sign: "¥" },
  TRY: { title: "TRY", currency: "TRY", active: true, sign: "₺" },
  ZAR: { title: "ZAR", currency: "ZAR", active: true, sign: "R" },
};
export const fiatCurrenciesAvailable: Array<string> = Object.values(
  fiatCurrencies
).reduce((arr: Array<string>, curr) => {
  if (curr.active) arr.push(curr.currency);
  return arr;
}, []);

export const getTokenToFixed = (token: string) => {
  switch (token) {
    case Tokens.SHIB:
    case Tokens.SSHIB:
      return 8;

    default:
      return 2;
  }
};

export const altCoins = [
  "ethereum",
  "tether",
  "eos",
  "litecoin",
  "solana",
  "usdc",
  "bitcoin-cash",
  "dogecoin",
  "cardano",
  "bnb",
  "dai",
  "tron",
  "toncoin",
  "ripple",
  "shib",
  "trump",
];
