import { Fiats } from "@sbetdev2/sbet-utils/constants";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { CurrencyType } from "constants/types.ts";

import { formatFiatAmount, formatTokenAmount } from "utils/currencyUtils";

import styles from "./AssetsTotal.module.scss";

type Props = {
  id?: string;
  amount: number;
  fiatAmount: number;
  currency: CurrencyType | null;
  fiatCurrency: Fiats;
};

export const AssetsTotal: FC<Props> = ({
  id,
  amount,
  currency,
  fiatAmount,
  fiatCurrency,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.total}>
      <div className={styles.title}>
        <span>{t("Estimated balance")}</span>
        {id && <span>ID: {id}</span>}
      </div>

      <div className={styles.amountWrapper}>
        <div className={styles.amountValue}>
          {formatTokenAmount(amount, currency?.name)} {currency?.displayName}
        </div>
        {fiatCurrency && (
          <div className={styles.fiatAmount}>
            {formatFiatAmount(fiatAmount, fiatCurrency)}
          </div>
        )}
      </div>
    </div>
  );
};
