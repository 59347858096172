import { currencies } from "constants/currencies.ts";
import { leaguesNamesExceptions } from "constants/events";
import { sports } from "constants/sports";

export const unprojectOdd = (o) => {
  if (Array.isArray(o)) return o.map(unprojectOdd);
  if (!o) return o;
  return {
    bet: !!o.b,
    _id: o._,
    home: o.h,
    away: o.a,
    draw: o.d,
    over: o.o,
    under: o.u,
    odd: o.dd,
    even: o.en,
    yes: o.y,
    no: o.n,
    bid: o.bid,
    vip: o.vip,
    name: o.__,
  };
};

export const unprojectBettable = (e, isVip = false, filterVip = false) => {
  if (!e) return null;
  return {
    _id: e._,
    league: e.l,
    leagueName: e.ln,
    sport: e.sp,
    type: e._t,
    away: e.a,
    home: e.h,
    bet: !!e.b,
    status: e.s,
    starts: e.t && new Date(e.t),
    oddsTs: e.ts && new Date(e.ts),
    slug: e.u,
    leagueSlug: e.ul,
    odds:
      e.o &&
      Object.entries(e.o).reduce((p, [k, o]) => {
        if (k === "b") {
          p.bet = o;
        } else {
          const odd = unprojectOdd(o);
          if (filterVip && odd && !!odd.vip && !isVip) return p;
          p[k] = odd;
        }
        return p;
      }, {}),
    statusText: e.st,
    results:
      e.r &&
      Object.entries(e.r).reduce((p, [k, r]) => {
        if (k === "comment") {
          return p;
        } else if (r) {
          p[k] = { home: r[0], away: r[1], name: r[2] };
        }
        return p;
      }, {}),
    info: e.i && e.i.map((x) => ({ text: x.t })),
    betRisk: e.br,
    eventRisk: e.er,
    liveStream: e.ls,
    isMature: e.tim,
    teamInfo: e.ti
      ? {
          home: e.ti.h
            ? { serve: !!e.ti.h.s, colors: e.ti.h.c ? e.ti.h.c.split(",") : [] }
            : null,
          away: e.ti.a
            ? { serve: !!e.ti.a.s, colors: e.ti.a.c ? e.ti.a.c.split(",") : [] }
            : null,
        }
      : null,
  };
};

export const normalizeEvent = (event, sport, isVip = false) => {
  const e = unprojectBettable(event, isVip);
  if (e) e.sport = sport;
  return e;
};

export const parseLeagueName = (name, responseGroup, sport) => {
  if (!name || typeof name !== "string") return { group: name + "", short: name + "" };
  let group;
  leaguesNamesExceptions.some((nameException) => {
    if (name.startsWith(nameException)) {
      group = nameException;
      return true;
    }
    return false;
  });

  if (group) {
    if (group.length === name.length) {
      return { group, short: group };
    }

    return {
      group,
      short: name
        .substring(group.length)
        .replace(/^\s*?:/, "")
        .trim(),
    };
  }
  const separatorIndex = name.indexOf(":");
  const hasSeparator = separatorIndex >= 0;

  if (sport === sports.esports && responseGroup) {
    return {
      group: responseGroup,
      short: name,
    };
  }
  if (!hasSeparator) {
    return { group: name, short: name };
  }

  return {
    group: name.substring(0, separatorIndex).trim(),
    short: name.substring(separatorIndex + 1).trim(),
  };
};

export const normalizeLeague = (league) => {
  if (!league) return null;

  const { group, short } = parseLeagueName(league.n, league.g, league.s);

  return {
    _id: league._,
    name: league.n,
    order: league.o,
    sport: league.s,
    betRisk: league.br,
    vipOnly: league.v,
    liveStream: league.ls,
    slug: league.u,
    group,
    short,
    isSeoLeague: league.sl,
  };
};

export const normalizeBet = (bet) => {
  if (!bet) return null;
  if (bet.createdAt) bet.createdAt = new Date(bet.createdAt);
  if (bet.updatedAt) bet.updatedAt = new Date(bet.updatedAt);
  if (bet.amount) {
    const { precision = 4 } = currencies[bet.token || "EOS"] || {};
    bet.amount /= Math.pow(10, precision);
  }
  const { legs } = bet;
  if (!legs) return bet;
  for (let i = 0; i < legs.length; ++i) {
    const leg = legs[i];
    if (leg.event && leg.event.starts) leg.event.starts = new Date(leg.event.starts);
  }
  return bet;
};
