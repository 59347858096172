import cx from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import { sports } from "constants/sports";

import NavLink from "components/common/NavLink.tsx";
import Accordion from "components/data/Accordion";

import { isBrowser } from "utils/browserUtils";
import { getPrimaryLeagueOrderThreshold } from "utils/eventUtils";
import { getSlugWithoutId } from "utils/urlUtils.ts";

import { setEventGroupsOpenState } from "../../actions/uiActions";
import { getEventGroups } from "../../selectors/uiSelectors";
import { MemoizedLeaguesList } from "./leaguesList";

const eventsNumToCollapse = 20;
const leaguesNumToCollapse = 2;

const getEventGroupId = (live, id) => `${live ? "live" : "prematch"}-${id}`;

const GroupEvents = ({
  group,
  startOpen,
  forceOpen,
  scrollTo,
  live,
  requestedLeague,
  invertCountBadge = false,
  shortLeague = true,
  showLiveIcon = false,
  showSportIcon = false,
  topHighlight = true,
  hideBadge = false,
  showAll,
  sportId = "",
}) => {
  const { t } = useTranslation();
  const { name, leagues, count, order } = group;
  const dispatch = useDispatch();
  const eventGroups = useSelector(getEventGroups);
  const totalEvents = useMemo(() => {
    let total = 0;
    leagues.forEach((x) => {
      if (x.events) {
        total += x.events.length;
      }
    });

    return total;
  }, [leagues]);
  const [expanded, setExpanded] = useState([]);
  useEffect(() => {
    if (
      forceOpen ||
      totalEvents < eventsNumToCollapse ||
      leagues.length < leaguesNumToCollapse ||
      requestedLeague
    ) {
      if (requestedLeague) {
        setExpanded([requestedLeague]);
      } else {
        setExpanded(leagues.map((x) => getSlugWithoutId(x.league.slug)));
      }
    }
  }, [forceOpen, JSON.stringify(leagues)]);
  const toggleLeague = useCallback(
    (id) => {
      const update = [...expanded];
      const index = expanded.indexOf(id);

      const isExpanded = index !== -1;
      dispatch(
        setEventGroupsOpenState(
          getEventGroupId(live, id),
          typeof eventGroups[getEventGroupId(live, id)] === "undefined"
            ? !isExpanded
            : !eventGroups[getEventGroupId(live, id)]
        )
      );

      if (!isExpanded) {
        update.push(id);
      } else {
        update.splice(index, 1);
      }
      setExpanded(update);
    },
    [expanded, setExpanded, live]
  );
  const leagueHeader = leagues.length > 1 || leagues[0].league.name !== name;
  const isHighlighted =
    topHighlight && count && order && order < getPrimaryLeagueOrderThreshold(live);
  const linkStyle = {
    display: "inline-flex",
    alignItems: "center",
    color: isHighlighted ? "#68e690" : "#fff",
    position: "relative",
    paddingLeft: showSportIcon ? 30 : 0,
  };
  const liveIndicator = showLiveIcon ? <span className="live-indicator" /> : null;
  const formattedName = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <Accordion
      headerClass={cx({
        "group-top": isHighlighted,
        "inverted-count": invertCountBadge,
      })}
      header={
        <span>
          {sportId === sports.esports ? (
            <NavLink
              to={`/sports/esports/esports-games/${group.slug}`}
              style={linkStyle}
              onClick={(e) => {
                if (isBrowser()) {
                  e.preventDefault();
                }
              }}
            >
              {t(formattedName)} {liveIndicator}
            </NavLink>
          ) : (
            t(name)
          )}{" "}
          {liveIndicator}
          {!hideBadge ? <Badge pill>{count}</Badge> : null}
          {showAll ? (
            <NavLink
              className={"events-grid-show-all"}
              to={showAll}
              onClick={(e) => {
                e.stopPropagation();
                try {
                  window.scrollTo(0, 0);
                } catch (e) {}
              }}
            >
              {t("All")}
            </NavLink>
          ) : null}
        </span>
      }
      startOpen={!isBrowser() || startOpen}
      forceOpen={!isBrowser() || forceOpen}
      scrollTo={scrollTo && startOpen}
      bodyClass="group-events-body"
      onToggle={(isOpen) => {
        if (!isOpen) {
          if (
            forceOpen ||
            totalEvents < eventsNumToCollapse ||
            leagues.length < leaguesNumToCollapse
          ) {
            setExpanded(leagues.map((x) => getSlugWithoutId(x.league.slug)));
          } else {
            setExpanded([]);
          }
        }
      }}
      forceRender
    >
      {({ open }) => {
        return (
          <>
            {leagues.map((x) => {
              const isExpanded =
                typeof eventGroups[
                  getEventGroupId(live, getSlugWithoutId(x.league.slug))
                ] === "undefined"
                  ? expanded.includes(getSlugWithoutId(x.league.slug))
                  : eventGroups[getEventGroupId(live, getSlugWithoutId(x.league.slug))];
              return (
                <MemoizedLeaguesList
                  key={x.league._id}
                  open={open}
                  leagueHeader={leagueHeader}
                  x={x}
                  shortLeague={shortLeague}
                  live={live}
                  isExpanded={isExpanded}
                  toggleLeague={toggleLeague}
                  invertCountBadge={invertCountBadge}
                  showSportIcon={showSportIcon}
                />
              );
            })}
          </>
        );
      }}
    </Accordion>
  );
};

GroupEvents.propTypes = {
  group: PropTypes.object,
  startOpen: PropTypes.bool,
  forceOpen: PropTypes.bool,
  scrollTo: PropTypes.bool,
  live: PropTypes.bool,
  requestedLeague: PropTypes.string,
};

export default GroupEvents;
