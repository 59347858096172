import { Tokens } from "@sbetdev2/sbet-utils/constants";
import { getError } from "api/api";
import getEosApi from "api/eosApi";
import {
  acceptLicense,
  assignEOSAccount,
  fetchUserInfo,
  initUserSession,
  invalidateUserSession,
  updateUserMetaInfo,
  validateSession,
} from "api/loginApi";
import { LocationDescriptor } from "history";
import Cookies from "js-cookie";
import { RouterChildContext } from "react-router";
import { AppDispatch, RootState } from "redux";

import {
  getAccountCurrency,
  getAccountName,
  getAccountUser,
  getLoginSigningIn,
  getLoginToken,
} from "selectors/loginSelectors";

import { lockTfaConfirm } from "actions/tfaConfirmActions";

import { CHANGE_EMAIL_STEP } from "constants/changeEmail";

import { clearAuthBonusCookies, clearStoredBonus } from "components/bonus/bonus.utils";

import analytics from "utils/analyticsUtils";
import {
  getReferral,
  getSavedCurrency,
  getUserDeviceInfo,
  getUserSourceData,
  getUtmParams,
  localStorageUtils,
} from "utils/browserUtils";
import { cancellableAction } from "utils/cancellableAction";
import { getHostname, getRootDomain } from "utils/domainUtils.ts";

import {
  changeUserEmail,
  resendChangeUserEmailCode,
  verifyChangeUserEmailCode,
} from "../api/userApi";
import {
  BET_CANCEL,
  BONUS_PACKS_CLEAR,
  CHALLENGE_RESET,
  CHANGE_EMAIL_HIDE_POPUP,
  CHANGE_EMAIL_SET_ERROR,
  CHANGE_EMAIL_SET_LOADING,
  CHANGE_EMAIL_SET_STEP,
  CHANGE_EMAIL_SHOW_POPUP,
  CURRENCY_CHANGED,
  LOGIN_BALANCE,
  LOGIN_COMPLETE,
  LOGIN_ERROR,
  LOGIN_HIDE_POPUP,
  LOGIN_INIT,
  LOGIN_PROGRESS,
  LOGIN_RESET,
  LOGIN_SET_EOS_ACCOUNT,
  LOGIN_SET_SIGNING_IN,
  LOGIN_SHOW_POPUP,
  LOGIN_UPDATE_USER,
  LOGOUT_COMPLETE,
  SET_NEW_EMAIL,
  SIGNUP_HIDE_POPUP,
  SIGNUP_SHOW_POPUP,
} from "./actionTypes";
import { walletLoadBalances } from "./walletActions";

type History = RouterChildContext["router"]["history"];

export const getUserInfo =
  (history: History) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const token = getLoginToken(getState());
      if (!token) return null;

      const { status } = await validateSession(token);
      if (status === "failed") return null;

      const { user, tfaLocked } = await fetchUserInfo(token);

      if (tfaLocked) {
        dispatch(lockTfaConfirm());
        return null;
      } else if (!!user.excludedAt && history) {
        history.replace("#reactivate-account");
      }
      dispatch({
        type: LOGIN_UPDATE_USER,
        payload: { user },
      });
      return user;
    } catch (e) {
      return null;
    }
  };

export const initSession = async (account: { name: string }, dispatch: AppDispatch) => {
  try {
    const referral = getReferral() || "";
    const utm = getUtmParams() || {};
    const userSourceData = getUserSourceData();
    const userDeviceInfo = await getUserDeviceInfo();

    const { token, user } = await initUserSession("scatter", {
      ...account,
      eosAccount: account.name,
      referral,
      utm,
      userMetaInfo: userDeviceInfo,
      userSourceData,
    });
    localStorageUtils.setItem(
      "auth",
      JSON.stringify({
        token,
        username: user.name || user.eosAccount,
      })
    );

    dispatch({
      type: LOGIN_COMPLETE,
      payload: { token, account: user.eosAccount, user },
    });

    return user;
  } catch (e) {
    const error = getError(e);
    dispatch({ type: LOGIN_ERROR, payload: { error } });
  }
};

export const loginSetSigningIn = (payload: boolean) => ({
  type: LOGIN_SET_SIGNING_IN,
  payload,
});

export const logout = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch({ type: LOGIN_PROGRESS, payload: "Logging Out..." });
    dispatch({ type: BET_CANCEL });
    const token = getLoginToken(getState());
    if (token) {
      await invalidateUserSession(token);
    }
  } catch (e) {
    // do nothing
  }
  try {
    const user = getAccountUser(getState());
    if (user && user.provider === "scatter") {
      await getEosApi().logout();
    }
  } catch (e) {
    // do nothing
  } finally {
    const domain = `.${getRootDomain(getHostname())}`;
    Cookies.remove("auth_token", { path: "/", domain });
    localStorageUtils.removeItem("activeWallet");
    localStorageUtils.removeItem("auth");
    clearStoredBonus();
    clearAuthBonusCookies();
    dispatch({ type: BONUS_PACKS_CLEAR });
    dispatch({ type: CHALLENGE_RESET });
    dispatch({ type: LOGOUT_COMPLETE });
  }
};

export const init =
  (wallet: string, history: History, force = false) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    let renew = false;
    try {
      if (window.location.pathname.includes("/activate")) {
        return;
      }
      if (!wallet) {
        renew = true;
        wallet = localStorageUtils.getItem("activeWallet") || "";
      } else {
        localStorageUtils.setItem("activeWallet", wallet);
      }
      const authData = localStorageUtils.getItem("auth") || "";
      const auth = JSON.parse(authData);
      if (auth && auth.social) return;
    } catch (e) {
      // do nothing
    }
    try {
      if (getState().login.init && !wallet && !force) return;
      await cancellableAction(
        "login",
        async (state: RootState) => {
          try {
            if (getLoginSigningIn(getState()) && !force) return;
            dispatch({ type: LOGIN_INIT });
            dispatch(loginSetSigningIn(true));
            if (state.aborted) return;
            const account = await getEosApi().init(renew);
            if (!account || account.error) {
              throw new Error(
                (account && account.error && account.error.message) || "NO_EOS"
              );
            }
            if (state.aborted) return;
            let user = await dispatch(getUserInfo(history));
            if (!user || user.eosAccount !== account.name) {
              user = await initSession(account, dispatch);
            }
            dispatch({ type: LOGIN_HIDE_POPUP });
            if (state.aborted) return;
            let currencyName = getSavedCurrency() as Tokens;
            if (!currencyName)
              currencyName = user.provider === "scatter" ? Tokens.EOS : Tokens.BTC;
            const balance = await getEosApi().getAccountBalance(currencyName);
            dispatch({
              type: CURRENCY_CHANGED,
              payload: { balance, currencyName },
            });
            if (state.aborted) return;
            dispatch({ type: LOGIN_BALANCE, payload: balance });
          } catch (e) {
            // check for unwanted login reset
            const authData = localStorageUtils.getItem("auth") || "";
            const auth = JSON.parse(authData);
            if (auth && auth.social) return;

            localStorageUtils.removeItem("activeWallet");
            dispatch({ type: LOGIN_RESET });
            if (getError(e) === "NO_EOS") {
              localStorageUtils.setItem("noEos", "noEos");
            }
          }
        },
        () => {
          dispatch({ type: LOGIN_RESET });
        }
      );
    } catch (e) {
      dispatch({ type: LOGIN_RESET });
      if (getError(e) === "NO_EOS") {
        localStorageUtils.setItem("noEos", "noEos");
      }
    } finally {
      dispatch(loginSetSigningIn(false));
      if (
        location.pathname.includes("landing/") ||
        location.pathname.includes("promotions/bonus")
      ) {
        history?.push("/");
      }
    }
  };

export const clearError = () => ({ type: LOGIN_ERROR, payload: null });

export const loginWithToken =
  (token: string, history: History) => async (dispatch: AppDispatch) => {
    try {
      await Promise.race([
        cancellableAction(
          "login",
          async (state: RootState) => {
            dispatch(loginSetSigningIn(true));
            dispatch({ type: LOGIN_PROGRESS, payload: "Logging In..." });
            if (state.aborted) return;
            const { user, tfaLocked } = await fetchUserInfo(token);

            if (tfaLocked) {
              dispatch(lockTfaConfirm());
              return;
            }
            if (!user) {
              dispatch({ type: LOGIN_RESET });
              return;
            }
            if (state.aborted) return;

            localStorageUtils.removeItem("noEos");

            dispatch({
              type: LOGIN_COMPLETE,
              payload: { token, account: user.eosAccount, user },
            });

            if (!!user?.excludedAt && history) {
              history.replace("#reactivate-account");
            }

            let currencyName = getSavedCurrency();
            if (!currencyName)
              currencyName = user.provider === "scatter" ? Tokens.EOS : Tokens.BTC;
            const balance = await getEosApi().getAccountBalance(currencyName as Tokens);
            dispatch({
              type: CURRENCY_CHANGED,
              payload: { balance, currencyName },
            });
          },
          () => {
            dispatch({ type: LOGIN_RESET });
          }
        ),
        new Promise<void>((resolve) =>
          setTimeout(() => {
            dispatch({ type: LOGIN_PROGRESS, payload: null });
            resolve();
          }, 30000)
        ),
      ]);
    } catch (e) {
      dispatch({ type: LOGIN_RESET });
    }
  };

export const login =
  ({ onFail = {} } = {}, history: History) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const isSigningIn = getLoginSigningIn(getState());
      if (isSigningIn) {
        dispatch({ type: LOGIN_PROGRESS, payload: "Logging In..." });
      }
      await Promise.race([
        cancellableAction(
          "login",
          async (state: RootState) => {
            try {
              dispatch(loginSetSigningIn(true));
              dispatch({ type: LOGIN_PROGRESS, payload: "Logging In..." });
              if (state.aborted) return;
              const account = await getEosApi().login();
              if (!account || account.error) {
                throw new Error((account.error && account.error.message) || "NO_EOS");
              }
              if (state.aborted) return;
              let user = await dispatch(getUserInfo(history));
              if (!user || user.eosAccount !== account.name) {
                user = await initSession(account, dispatch);
              }

              dispatch({ type: LOGIN_HIDE_POPUP });
              if (state.aborted) return;
              let currencyName = getSavedCurrency() as Tokens;
              if (!currencyName)
                currencyName = user.provider === "scatter" ? Tokens.EOS : Tokens.BTC;
              const balance = await getEosApi().getAccountBalance(currencyName);
              dispatch({
                type: CURRENCY_CHANGED,
                payload: { balance, currencyName },
              });
            } catch (e) {
              dispatch({ type: LOGIN_ERROR, payload: getError(e) });
              if (typeof onFail === "function") {
                onFail();
              }
            }
          },
          () => {
            localStorageUtils.removeItem("activeWallet");
            dispatch({ type: LOGIN_RESET });
          }
        ),
        new Promise<void>((resolve) =>
          setTimeout(() => {
            localStorageUtils.removeItem("activeWallet");
            dispatch({ type: LOGIN_PROGRESS, payload: null });
            resolve();
          }, 30000)
        ),
      ]);
      dispatch(loginSetSigningIn(false));
    } catch (e) {
      dispatch({ type: LOGIN_ERROR, payload: getError(e) });
      if (typeof onFail === "function") {
        onFail();
      }
      localStorageUtils.removeItem("activeWallet");
      dispatch(loginSetSigningIn(false));
    }
  };

export const updateBalance =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!getAccountName(getState())) return;

    try {
      dispatch(walletLoadBalances());
      const currency = getAccountCurrency(getState());
      const user = getAccountUser(getState());
      if (!user.eosAccount) return;
      const balance = await getEosApi().getAccountBalance(currency);
      dispatch({ type: LOGIN_BALANCE, payload: balance });
    } catch {
      // do nothing
    }
  };

export const showLoginPopup = () => ({ type: LOGIN_SHOW_POPUP });
export const showSignUpPopup = () => ({ type: SIGNUP_SHOW_POPUP });

export const hideLoginPopup = () => ({ type: LOGIN_HIDE_POPUP });
export const hideSignUpPopup = () => ({ type: SIGNUP_HIDE_POPUP });

export const newEmailRequest =
  (email: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const token = getLoginToken(getState());

      dispatch({ type: CHANGE_EMAIL_SET_LOADING, payload: true });

      const {
        data: { status, error },
      } = await changeUserEmail(token, email);

      dispatch({ type: CHANGE_EMAIL_SET_LOADING, payload: false });

      if (status !== "ok") {
        return dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error } });
      }

      dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error: null } });

      dispatch({
        type: SET_NEW_EMAIL,
        payload: { newEmail: email },
      });

      dispatch({
        type: CHANGE_EMAIL_SET_STEP,
        payload: { step: CHANGE_EMAIL_STEP.verify },
      });
    } catch (e) {
      const error = getError(e);
      dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error } });
    }
  };

export const resendNewEmailCode =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const token = getLoginToken(getState());

      dispatch({ type: CHANGE_EMAIL_SET_LOADING, payload: true });

      const {
        data: { status, error },
      } = await resendChangeUserEmailCode(token);

      console.log("RESEND CHANGE EMAIL", status, error);

      dispatch({ type: CHANGE_EMAIL_SET_LOADING, payload: false });

      if (error) {
        return dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error } });
      }

      dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error: null } });
    } catch (e) {
      const error = getError(e);
      dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error } });
    }
  };

export const newEmailCodeVerify =
  (code: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const token = getLoginToken(getState());

      dispatch({ type: CHANGE_EMAIL_SET_LOADING, payload: true });

      const {
        data: { user, error },
      } = await verifyChangeUserEmailCode(token, code);

      dispatch({ type: CHANGE_EMAIL_SET_LOADING, payload: false });

      if (error) {
        return dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error } });
      }

      dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error: null } });

      dispatch({
        type: LOGIN_UPDATE_USER,
        payload: { user },
      });

      dispatch({
        type: CHANGE_EMAIL_SET_STEP,
        payload: { step: CHANGE_EMAIL_STEP.enter },
      });

      dispatch({ type: CHANGE_EMAIL_HIDE_POPUP });
    } catch (e) {
      const error = getError(e);
      dispatch({ type: CHANGE_EMAIL_SET_ERROR, payload: { error } });
    }
  };
export const setNewEmail = (newEmail: string) => ({
  type: SET_NEW_EMAIL,
  payload: { newEmail },
});

export const showChangeEmailPopup = () => ({ type: CHANGE_EMAIL_SHOW_POPUP });
export const hideChangeEmailPopup = () => ({ type: CHANGE_EMAIL_HIDE_POPUP });
export const setChangeEmailStep = (step: string) => ({
  type: CHANGE_EMAIL_SET_STEP,
  payload: { step },
});

export const setUserMetaInfo =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const token = getLoginToken(getState());
      const referral = getReferral() || "";
      const utm = getUtmParams() || {};
      await updateUserMetaInfo(token, referral, utm);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

export const userAcceptLicense =
  (push: (location: LocationDescriptor<unknown>) => void) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const token = getLoginToken(getState());
      const { accepted } = await acceptLicense(token);
      const user = getAccountUser(getState());
      if (accepted) {
        clearAuthBonusCookies();
        dispatch({
          type: LOGIN_UPDATE_USER,
          payload: {
            user: {
              ...user,
              licenseAccepted: true,
            },
          },
        });
        analytics.signup.success();
        push({ hash: "#wallet" });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

export const getEOSAccount =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const user = getAccountUser(getState());
    if (!user) return;
    const eosAccount = await assignEOSAccount(user._id);
    if (eosAccount) {
      dispatch({
        type: LOGIN_SET_EOS_ACCOUNT,
        payload: { eosAccount },
      });
    }
  };
