import { useMemo } from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";

import SportDateFilter from "containers/sports/SportDateFilter";
import SportEvents from "containers/sports/SportEvents";
import SportFilter from "containers/sports/SportFilter";
import SportLiveStreamFilter from "containers/sports/SportLiveStreamFilter";

import { getLiveSportsCount } from "selectors/eventSelectors";
import { getPageIds, getPageSeoContent } from "selectors/pageSelectors";
import { getLiveSports } from "selectors/sportsSelectors";

import { sports } from "constants/sports";

import { getHostname } from "utils/domainUtils.ts";

interface Params {
  type?: string;
  league?: string;
  game?: string;
  sport?: string;
  esportsGame?: string;
}

interface Sport {
  _id: number;
}

interface LiveCounts {
  [key: number]: number;
}

interface PageIds {
  sportId?: number;
}

const LiveSportsHomepage: React.FC<{
  league: string;
  game: string;
  esportsGame?: string;
}> = ({ league, game, esportsGame }) => {
  const liveCounts = useSelector(getLiveSportsCount) as LiveCounts;
  const liveSports = useSelector<any, Sport[]>(getLiveSports);
  const sportId = liveSports.find(({ _id }) => !!liveCounts[_id])?._id || sports.soccer;
  return (
    <SportEvents
      sportId={sportId}
      live={true}
      league={league}
      game={game}
      esportsGame={esportsGame}
    />
  );
};

const SportPage: React.FC = () => {
  const params = useParams<Params>();
  const { type, league, game, sport, esportsGame } = params;
  const { sportId } = useSelector<any, PageIds>(getPageIds);
  const seoContent = useSelector(getPageSeoContent);
  const live = type === "live";
  const location = useLocation();
  const isLiveHomePage = !!/\/live[/]?$/.exec(location.pathname);

  const canonicalUrl = useMemo(() => {
    if (live && sport && league)
      return `https://${getHostname()}/sports/${sport}/${league}`;
    if (live && sport) return `https://${getHostname()}/live`;
    if (esportsGame && !seoContent) return `https://${getHostname()}/sports/esports`;

    return null;
  }, [sport, league, live]);

  return (
    <>
      {canonicalUrl && (
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
      )}
      <div className="main-container">
        <div className="d-flex justify-content-between align-items-center">
          {!live ? <SportDateFilter className="mr-2" /> : null}
          {live ? <SportLiveStreamFilter className="mr-2" /> : null}
          <SportFilter className="sport-filter" />
        </div>
        {isLiveHomePage ? (
          <LiveSportsHomepage league={league || ""} game={game || ""} />
        ) : (
          <SportEvents
            sportId={sportId || sports.soccer}
            game={game}
            league={league}
            live={live}
            esportsGame={esportsGame}
          />
        )}
      </div>
    </>
  );
};

export default SportPage;
